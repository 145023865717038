<template>
  <div class="main-container">
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
    <div class="detail-container">
      <h2 class="title">{{ detailData.title }}</h2>
      <div class="detail-cover-pic">
        <img :src="detailData.photo_bg" />
      </div>
      <div class="detail-time">
        <h3>活动时间：</h3>
        <p>{{ getTime(detailData.start_at) }} 至 {{ getTime(detailData.end_at) }}</p>
      </div>
      <div class="detail-content">
        <h3>活动内容：</h3>
        <p
          class="content"
          v-html="detailData.content" />
      </div>
    </div>
    <button
      v-if="this.eventLink"
      class="btn"
      :class="isInProgress ? 'btn-apply' : 'btn-apply-disable'"
      @click="handleApply">{{ applyBtnText }}</button>
  </div>
</template>

<script>
import { isBetweenTwoDate, dateFormat } from '@/utils/time'
import { has } from 'lodash'
import moment from 'moment'

export default {
  props: {
    detailData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isInProgress: {
      get () {
        return isBetweenTwoDate(this.detailData.start_at, this.detailData.end_at)
      }
    },
    eventLink: {
      get () {
        if (
          has(this.detailData, 'eventLink') &&
          this.detailData.eventLink
        ) {
          return this.detailData.eventLink
        }
        return false
      }
    },
    applyBtnText: {
      get () {
        if (this.isInProgress) {
          return '立即参加'
        }
        return '活动已结束'
      }
    },
    breadcrumb: {
      get () {
        return [
          {
            text: '首页',
            to: { name: 'Home' }
          },
          {
            text: '专属福利',
            to: { name: 'Welfare' }
          },
          {
            text: this.detailData.title,
            active: true
          }
        ]
      }
    }
  },
  methods: {
    handleApply () {
      // console.log(this.eventLink)
    },
    getTime (date) {
      return moment(date).format('YYYY-MM-DD hh:mm')
    }
  }
}
</script>

<style lang="scss">
  .content {
    & img {
      max-width: 100%;
      height: auto;
    }
  }
</style>
<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #FFFFFF;
  .breadcrumb {
    padding: 18px 0 12px;
    margin-bottom: 0;
    background-color: #FFFFFF;
    .breadcrumb-item + .breadcrumb-item::before {
      content: '>';
      color: #828282;
    }
    .active {
      color: #828282;
      & > span {
        max-width: 30em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
  }
  .detail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-grow: 99;
    margin: 12px 0;
    .title {
      font-weight: 500;
      color: #4F4F4F;
      text-align: left;
    }
    .detail-cover-pic {
      width: 100%;
      img {
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
      }
    }
    .detail-time {
      text-align: left;
    }
    .detail-content {
      text-align: left;
      .content {
        flex-grow: 99;
        text-align: left;
        color: #000000;
        img {
          width: 100%;
        }
      }
    }
  }

  .btn {
    border: 0;
    box-shadow: 0px 2px 8px rgba(235, 87, 87, 0.25);
    color: #FFFFFF;
    width: fit-content;
    align-self: center;
  }
  .btn-apply {
    background: linear-gradient(234.8deg, #FF9356 7.81%, #EE4A72 73.1%);
  }
  .btn-apply-disable {
    background: #C4C4C4;
    box-shadow: none;
  }
}
@media (max-width: 575.98px) {
  .main-container {
    // gap: 12px;
    padding: 15px;
    border-radius: 0;
    .breadcrumb {display: none;}
    .detail-container {
      margin: 0 0 10px;
      .title {
        font-size: 21px;
        margin: 5px 0 20px;
        line-height: 30px;
      }
      .detail-cover-pic {margin-bottom: 20px;}
      .detail-time {
        margin-bottom: 25px;
        h3 {
          font-size: 15px;
          line-height: 20px;
        }
        p {
          margin-top: 5px;
          font-size: 15px;
          line-height: 25px;
        }
      }
      .detail-content {
        width: 100%;
        h3 {
          font-size: 15px;
          line-height: 20px;
        }
        .content {
          margin-top: 5px;
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
    .btn {
      font-weight: 500;
      font-size: 15px;
      // margin-top: 10px;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-container {
    // gap: 12px;
    padding: 15px;
    border-radius: 0;
    .breadcrumb {display: none;}
    .detail-container {
      margin: 0 0 10px;
      .title {
        font-size: 21px;
        margin: 5px 0 20px;
        line-height: 30px;
      }
      .detail-cover-pic {margin-bottom: 20px;}
      .detail-time {
        margin-bottom: 25px;
        h3 {
          font-size: 15px;
          line-height: 20px;
        }
        p {
          margin-top: 5px;
          font-size: 15px;
          line-height: 25px;
        }
      }
      .detail-content {
        width: 100%;
        h3 {
          font-size: 15px;
          line-height: 20px;
        }
        .content {
          margin-top: 5px;
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
    .btn {
      font-weight: 500;
      font-size: 15px;
      // margin-top: 10px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-container {
    // gap: 12px;
    padding: 5px 30px 10px;
    border-radius: 5px;
    .breadcrumb {
      .breadcrumb-item + .breadcrumb-item::before {
        font-size: 12px;
        margin-top: 1px;
      }
      .breadcrumb-item {
        font-size: 12px;
      }
    }
    .detail-container {
      // gap: 20px;
      margin: 0 0 10px;
      .title {
        font-size: 28px;
        margin-bottom: 15px;
        line-height: 40px;
      }
      .detail-cover-pic {margin-bottom: 25px;}
      .detail-time {
        margin-bottom: 25px;
        h3 {
          font-size: 16px;
        }
        p {
          margin-top: 5px;
          font-size: 14px;
        }
      }
      .detail-content {
        width: 100%;
        h3 {
          font-size: 16px;
        }
        .content {
          margin-top: 5px;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .btn {
      font-weight: 500;
      font-size: 16px;
      // margin-top: 10px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-container {
    // gap: 12px;
    padding: 5px 30px 10px;
    border-radius: 5px;
    .breadcrumb {
      .breadcrumb-item + .breadcrumb-item::before {
        font-size: 13px;
        margin-top: 1px;
      }
      .breadcrumb-item {
        font-size: 13px;
      }
    }
    .detail-container {
      // gap: 20px;
      margin: 0 0 15px;
      .title {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 40px;
      }
      .detail-cover-pic {margin-bottom: 30px;}
      .detail-time {
        margin-bottom: 30px;
        h3 {
          font-size: 16px;
        }
        p {
          margin-top: 5px;
          font-size: 15px;
        }
      }
      .detail-content {
        h3 {
          font-size: 16px;
        }
        .content {
          margin-top: 5px;
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
    .btn {
      font-weight: 500;
      font-size: 18px;
      // margin-top: 10px;
    }
  }
}
@media (min-width: 1200px) {
  .main-container {
    // gap: 12px;
    padding: 5px 50px 50px;
    border-radius: 5px;
    .breadcrumb {
      .breadcrumb-item + .breadcrumb-item::before {
        font-size: 14px;
        margin-top: 1px;
      }
      .breadcrumb-item {
        font-size: 14px;
      }
    }
    .detail-container {
      // gap: 20px;
      margin: 0 0 20px;
      .title {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 40px;
      }
      .detail-cover-pic {margin-bottom: 40px;}
      .detail-time {
        margin-bottom: 40px;
        h3 {
          font-size: 16px;
        }
        p {
          margin-top: 5px;
          font-size: 15px;
        }
      }
      .detail-content {
        h3 {
          font-size: 16px;
        }
        .content {
          margin-top: 5px;
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
    .btn {
      font-weight: 500;
      font-size: 18px;
      // margin-top: 10px;
    }
  }
}
</style>
