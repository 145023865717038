<template>
  <div class="welfare-commercial-content">
    <div class="commercial-item">
      <a
        v-for="(ad, idx) in ads"
        :key="'commercial-' + idx"
        class="ad-container"
        :class="Object.keys(ads).length > 1 ? 'ad-container-gap' : ''"
        :href="ad[0].href"
        :style="{
          backgroundImage: `url('${$common.resSrc(ad[0].photo)}')`,
          height: `${adHeight}px`,
        }"
        @click="sendVisit(ad[0].id)"
      >
      </a>
    </div>
  </div>
</template>

<script>
// import commercial from '@/assets/commercial/commercial-welfare.png'
// import { goWebsite } from '@/utils/route'
import { visit } from '@/api/client/visit'
import { getBanners } from '@/api/client/banner'
import resStatus from '@/constants/resStatus'
import { isMobile } from '@/utils/device'

export default {
  props: {
    ids: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // commercial,
      visitQuery: {
        to: 'ad',
        uuid: '',
        id: '',
      },
      bannerQuery: {
        position_ids: this.ids,
      },
      ads: [],
    }
  },
  computed: {
    adHeight() {
      const sw = window.innerWidth
      const adWidth = sw > 1200 ? 1200 : sw >= 768 && sw < 992 ? sw * 0.98 - 60 : sw
      return isMobile() ? adWidth - 30 : adWidth * 0.3216667
      // return isMobile() ? (adWidth - 30) * 0.36232 : adWidth * 0.34 * 0.3627
    },
  },
  methods: {
    // handleHrefCommercial () {
    //   const serviceUrl = 'https://vue.livelyhelp.chat/chatWindow.aspx?siteId=5000757&planId=645&chatGroup=1'
    //   goWebsite(serviceUrl, '_blank')
    // },
    sendVisit(id) {
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getCommercials() {
      getBanners(this.bannerQuery).then((res) => {
        if (res.status === resStatus.OK) {
          this.ads = res.data
          const hasData = Object.keys(res.data).length > 0
          this.$emit('showAds', hasData)
        }
      })
    },
  },
  created() {
    this.getCommercials()
    console.log('ff')
  },
}
</script>

<style lang="scss" scoped>
.welfare-commercial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .commercial-item {
    width: 100%;
    margin-bottom: 10px;
    .ad-container-gap {
      margin-bottom: 10px;
    }
    a {
      display: block;
      width: inherit;
      // min-height: 140px;
      cursor: pointer;
      background-size: cover;
      background-position: center;
      border-radius: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .welfare-commercial-content {
    margin-top: 0;
    .commercial-item {
      margin-bottom: 0;
      a {
        width: calc(100% - 30px);
        margin: 10px 15px;
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .welfare-commercial-content {
    margin-top: 0;
    .commercial-item {
      margin-bottom: 0;
      a {
        width: calc(100% - 30px);
        margin: 10px 15px;
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .welfare-commercial-content {
    margin-top: 0;
    .commercial-item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;
      .ad-container-gap {
        margin-bottom: 15px;
      }
      & a {
        margin: 15px 5px;
        &:nth-child(1) {
          margin-left: 20px;
        }
        &:nth-child(3) {
          margin-right: 20px;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
  .welfare-commercial-content {
    // gap: 10px;
    .commercial-item {
      width: 386px;
    }
  }
}
</style>
