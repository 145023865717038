<template>
  <div class="side-container">
    <div
      v-if="showPromote"
      class="welfare-commercial-container"
      :class="showTopPromote ? 'commercial-display' : ''">
      <advertise
        :ids="getPromoteIds[0]"
        @showAds="showTopAd"/>
    </div>
    <div class="welfare-list-container">
      <h3>其他熱門专属福利</h3>
      <div class="welfare-items">
        <router-link
          v-for="(item, idx) in welfareList"
          :key="idx"
          class="welfare-item"
          :to="{name: 'WelfareDetail', query: { id: item.id }}"
          @click.native="sendVisit(item.id)">
          <h4>{{ item.title }}</h4>
          <p>活动时间：{{ getTime(item.start_at) }} 到 {{ getTime(item.end_at) }}</p>
        </router-link>
      </div>
    </div>
    <div class="welfare-commercial-container" :class="showBtmPromote ? 'commercial-display' : ''">
      <advertise
        :ids="showPromote ? getPromoteIds[1] : ids"
        @showAds="showBtmAd"/>
    </div>
  </div>
</template>

<script>
import { visit } from '@/api/client/visit'
import { dateFormat } from '@/utils/time'
import advertise from './Commercial'
import { isMobile } from '@/utils/device'
import moment from 'moment'

export default {
  components: {
    advertise
  },
  data () {
    return {
      ids: '9,10,11',
      visitQuery: {
        to: 'benefit',
        uuid: '',
        id: ''
      },
      showTopPromote: false,
      showBtmPromote: false
    }
  },
  props: {
    welfareList: {
      type: Array,
      required: true
    }
  },
  computed: {
    showPromote () {
      return isMobile()
    },
    getPromoteIds () {
      return this.showPromote ? this.ids.split(',') : this.ids
    }
    // adHeight () {
    //   let sw = window.innerWidth
    //   let adWidth = sw > 1200 ? 1200 : (sw >= 768 && sw < 992 ? sw * 0.98 - 40 - 20 : sw)
    //   return this.showPromote ? (adWidth - 30) * 0.36232 : adWidth * 0.34 * 0.3627
    // }
  },
  methods: {
    sendVisit (id) {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.updateDetail()
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getTime (timestamp) {
      return moment(timestamp).format('YYYY-MM-DD')
    },
    updateDetail () {
      this.$emit('updateDetail')
    },
    showTopAd (boo) {
      this.showTopPromote = boo
    },
    showBtmAd (boo) {
      this.showBtmPromote = boo
    }
  }
}
</script>

<style lang="scss" scoped>
.side-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .welfare-commercial-container {
    display: none;
    width: 100%;
  }
  .commercial-display{display: block;}
  .welfare-list-container {
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .welfare-items {
      width: 100%;
      .welfare-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        h4 {
          width: 100%;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          color: #333333;
        }
        p {
          color: #4F4F4F;
        }
      }
      .welfare-item:not(:last-child) {
        border-bottom: 1px solid #E0E0E0;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .side-container {
    // gap: 20px;
    .welfare-commercial-container {
      background-color: #FFF;
      margin-bottom: 0;
      margin-top: 10px;
      &:last-child{
        padding-bottom: 10px;
      }
    }
    .welfare-list-container {
      border-radius: 0;
      padding: 20px 15px 0;
      // gap: 1px;
      margin-top: 10px;
      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
      .welfare-items {
        .welfare-item {
          // gap: 5px;
          padding: 15px 0;
          h4 {
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            margin-bottom: 10px;
          }
          p {
            font-size: 12px;
            line-height: 16.8px;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .side-container {
    // gap: 20px;
    .welfare-commercial-container {
      background-color: #FFF;
      margin-top: 10px;
      &:last-child{
        padding-bottom: 10px;
      }
    }
    .welfare-list-container {
      border-radius: 0;
      padding: 20px 15px 0;
      // gap: 1px;
      margin-top: 10px;
      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
      .welfare-items {
        .welfare-item {
          // gap: 5px;
          padding: 15px 0;
          h4 {
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            margin-bottom: 10px;
          }
          p {
            font-size: 12px;
            line-height: 16.8px;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .side-container {
    // gap: 20px;
    .welfare-commercial-container {
      margin-bottom: 0;
      margin-top: 15px;
      background-color: #FFF;
      border-radius: 5px;
    }
    .welfare-list-container {
      border-radius: 5px;
      padding: 30px 25px 5px;
      // gap: 1px;
      margin-top: 15px;
      h3 {
        font-weight: 500;
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 1px;
      }
      .welfare-items {
        .welfare-item {
          // gap: 5px;
          padding: 15px 0;
          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 5px;
          }
          p {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .side-container {
    // gap: 20px;
    .welfare-commercial-container {
      margin-bottom: 20px;
    }
    .welfare-list-container {
      border-radius: 5px;
      padding: 30px 25px 5px;
      // gap: 1px;
      h3 {
        font-weight: 500;
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 1px;
      }
      .welfare-items {
        .welfare-item {
          // gap: 5px;
          padding: 15px 0;
          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 5px;
          }
          p {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .side-container {
    // gap: 20px;
    .welfare-commercial-container {
      margin-bottom: 20px;
    }
    .welfare-list-container {
      border-radius: 5px;
      padding: 30px 25px 5px;
      // gap: 1px;
      h3 {
        font-weight: 500;
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 1px;
      }
      .welfare-items {
        .welfare-item {
          // gap: 5px;
          padding: 15px 0;
          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 5px;
          }
          p {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
</style>
