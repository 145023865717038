<template>
  <div class="welfare-detail-container">
    <welfare-detail-main
      class="welfare-detail-main"
      :detailData="detailData" />
    <welfare-detail-side
      class="welfare-detail-side"
      :welfareList="welfareList"
      @updateDetail="init"  />
  </div>
</template>

<script>
import WelfareDetailMain from './WelfareDetailMain'
import WelfareDetailSide from './WelfareDetailSide'
import { getWelfare, getWelfareList } from '@/api/client/welfare'
import resStatus from '@/constants/resStatus'

export default {
  components: {
    WelfareDetailMain,
    WelfareDetailSide
  },
  data () {
    return {
      detailData: {},
      welfareList: [],
      query: {
        state: 'ongoing',
        page: 1,
        pageSize: 5
      }
    }
  },
  methods: {
    init () {
      getWelfare(this.$route.query.id)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.detailData = res.data
          }
        })
      getWelfareList(this.query)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.welfareList = res.data
          }
        })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.welfare-detail-container {
  display: flex;
  justify-content: space-between;
  .welfare-detail-main {
    width: calc((100% - 20px) * 0.66);
    flex-grow: 99;
    margin-right: 20px;
  }
  .welfare-detail-side {
    width: calc((100% - 20px) * 0.34);
  }
}
@media (max-width: 575.98px) {
  .welfare-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0!important;
    .welfare-detail-main {
      width: 100%;
      flex-grow: 99;
      margin-right: 0;
    }
    .welfare-detail-side {
      width: 100%;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .welfare-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0!important;
    .welfare-detail-main {
      width: 100%;
      flex-grow: 99;
      margin-right: 0;
    }
    .welfare-detail-side {
      width: 100%;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .welfare-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0!important;
    .welfare-detail-main {
      width: 100%;
      flex-grow: 99;
      margin-right: 0;
    }
    .welfare-detail-side {
      width: 100%;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {
  .welfare-detail-container {
    // gap: 20px;
    .welfare-detail-side {
      width: 386px;
      min-width: 386px;
    }
  }
}
</style>
